import { App as CapacitorApp } from "@capacitor/app";
import { Network } from "@capacitor/network";
import { StatusBar } from "@capacitor/status-bar";
import { useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import {
  IonAlert,
  IonBackButton,
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { fetchFacilityDetails } from "@src/app/api/facility";
import { changeNFCTag, fetchShiftDetails } from "@src/app/api/shift";
import { NonIPConversionText } from "@src/app/components/shift/nonIPConversionMessage";
import { NoTimeSheetOptions } from "@src/app/components/shift/noTimeSheetOptions";
import { ColleagueList } from "@src/app/components/workWithFriends/colleagueList";
import { IShiftSlot, ShiftContext } from "@src/app/components/workWithFriends/interface";
import {
  fetchShiftSlotsFromOpenShifts,
  getColleagueList,
} from "@src/app/components/workWithFriends/utils";
import { WorkWithFriends } from "@src/app/components/workWithFriends/workWithFriends";
import { mapShiftStateDependencies } from "@src/app/hcpShifts/shiftDetails/mapShiftStateDependencies";
import { actionRecordShiftTimeFailure } from "@src/app/store/ongoingShifts/actions";
import { useGetAttendanceScoreProfile } from "@src/appV2/AttendanceScore/api/useGetAttendanceScoreProfile";
import { defaultAttendanceScoreFeatureFlag } from "@src/appV2/AttendanceScore/constants";
import { DeploymentEnvironmentName, environmentConfig } from "@src/appV2/environment";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { isCapacitorPlatform } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useAppInfo } from "@src/appV2/lib/utils/capacitor/useAppInfo";
import { useReliabilityRankingExperiment } from "@src/appV2/ReliabilityRank/useReliabilityRankExperiment";
import { ReviewForm } from "@src/appV2/Reviews";
import {
  LockScreenOrientationVariant,
  useDeprecatedScreenOrientation,
} from "@src/appV2/ScreenOrientation";
import { ClockOutPaymentMessageDialog } from "@src/appV2/Shifts/MyShifts/components/ClockOutPaymentMessageDialog";
import { WorkedShiftFeedbackModal } from "@src/appV2/Shifts/MyShifts/components/WorkedShiftFeedbackModal";
import { MyShiftUnavailablePage } from "@src/appV2/Shifts/MyShiftUnavailable/MyShiftUnavailablePage";
import { SOLVE_UNPAID_BREAKS_FF_MATCH_ALL_VERSIONS } from "@src/appV2/Shifts/Shift/constants";
import { useGetShiftState } from "@src/appV2/Shifts/Shift/ShiftState/api/useGetShiftState";
import { SolveUnpaidBreaksRolloutStage } from "@src/appV2/Shifts/Shift/ShiftState/types";
import { useQualityBonusFlag } from "@src/appV2/Shifts/Shift/useQualityBonusFlag";
import { useRevampWorkplaceSigningFlow } from "@src/appV2/Shifts/Shift/useRevampWorkplaceSigningFlow";
import { isSolveUnpaidBreaksFeatureEnabled } from "@src/appV2/Shifts/utils/isSolveUnpaidBreaksFeatureEnabled";
import { StreakAlertWrapper } from "@src/appV2/Streaks/components/StreakAlertWrapper";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { ShiftLoader } from "@src/lib/deprecatedCode";
import { DeviceNFCCapabilityForShift, Facility, Shift } from "@src/lib/interface";
import { RadarSdkTestPanel } from "@src/lib/RadarSdkTestPanel/RadarSdkTestPanel";
import { useAppSelector } from "@store/index";
import { ActionType } from "@store/ongoingShifts/model";
import { parseISO } from "date-fns";
import { omit } from "lodash";
import moment from "moment-timezone";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { useDeprecatedAlertsForShiftDetails } from "./alerts";
import { AttendancePolicyShiftActionFooter } from "./attendancePolicyShiftActionFooter";
import { MissingDocsDetails } from "./missingDocs";
import { RatingSuccess } from "./ratingSuccess";
import { ShiftInstructions } from "./shiftInstructions";
import { ShiftTimeline } from "./shiftTimeline";
import { ShiftActionFooterProps } from "./types";
import { USER_EVENTS } from "../../../constants/userEvents";
import { UploadFileModal } from "../../components/uploadFile";
import { useMissingDocuments } from "../../dayView/custom-hooks/useMissingDocuments";
import { FacilityDetails } from "../components/facilityDetails";
import { deviceNFCCapabilityForShift } from "../components/nfc/nfcHelper";
import { ShiftAttendanceScoreAccordion } from "../components/ShiftAttendanceScoreAccordion";
import { ShiftTitle } from "../components/shiftTitle";
import { TimeSheetSummary } from "../components/timesheetSummary";
import { getShiftTitleString } from "../helper";
import { useRevampWorkplaceSigningFlowV2 } from "../useRevampWorkplaceSigningFlowV2";

export function MyShiftDetailsPage() {
  const [showUploadTimesheetModal, setShowUploadTimesheetModal] = useState<boolean>(false);
  const [showFileExplorer, setShowFileExplorer] = useState<boolean>(false);

  const [shift, setShift] = useState<Shift | undefined>();
  const [shiftLoadError, setShiftLoadError] = useState<Error | undefined>();
  const [isShiftDetailsLoading, setIsShiftDetailsLoading] = useState(true);
  const shiftStateDependencies = isDefined(shift) ? mapShiftStateDependencies(shift) : undefined;
  const { data: shiftStateData, isLoading: isShiftStateLoading } = useGetShiftState(
    {
      shiftStateDependencies,
    },
    { keepPreviousData: true }
  );

  /**
   * When shiftStateDependencies is not defined, we are not fetching the shift state data
   * so isShiftStateLoading will always be true
   */
  const areShiftRequestsLoading =
    isShiftDetailsLoading || (isDefined(shiftStateDependencies) && isShiftStateLoading);

  const [openNoTimeSheetOptions, setOpenNoTimeSheetOptions] = useState<boolean>(false);
  const [openRating, setOpenRating] = useState<boolean>(false);
  const [isRatingSuccess, setIsRatingSuccess] = useState<boolean>(false);
  const [canShowUploadButton, setCanShowUploadButton] = useState<boolean>(true);
  const [isTimeSheetRequired, setIsTimeSheetRequired] = useState<boolean>(true);
  const [shiftSlot, setShiftSlot] = useState<IShiftSlot | null>(null);
  const { shiftId } = useParams<{ shiftId: string }>();
  const workShiftFeedbackModalState = useModalState();
  const { enabled: isQualityBonusEnabled } = useQualityBonusFlag();

  const platformIsCapacitor = isCapacitorPlatform();
  const { lockOrientation, unlockOrientation } = useDeprecatedScreenOrientation();
  const { useRevampedWorkplaceSignoutIsEnabled } = useRevampWorkplaceSigningFlow(
    shift?.facilityId ?? ""
  );
  const useRevampedWorkplaceSignoutV2IsEnabled = useRevampWorkplaceSigningFlowV2();

  const { userId, hideAsColleagueOnShifts } = useDefinedWorker();

  const isNewTimeSheetEnabled = shift?.facility?.featureSettings?.displayTimeSheetSummary ?? true;
  const isDigitalSignatureEnabled = shift?.facility?.featureSettings?.digitalSignature ?? true;
  const isCaliforniaTimesheetEnabled =
    shift?.facility?.featureSettings?.californiaTimesheet ?? false;
  const isUploadTimesheetNonIpEnabled =
    shift?.facility?.featureSettings?.uploadTimesheetNonIP ?? false;

  const workerFriends = useAppSelector(
    (state) => state.workerFriendsStore.workerFriends.data ?? []
  );

  const workerFriendRequests = useAppSelector(
    (state) => state.workerFriendsStore.workerFriendRequests.SENT.data
  );

  const fetchShiftSlot = useCallback(async () => {
    if (shift) {
      const fetchedShiftSlots = await fetchShiftSlotsFromOpenShifts([
        {
          ...shift,
          ...(shift?.facility?.userId ? { facilityId: shift?.facility?.userId } : {}),
        },
      ]);
      if (fetchedShiftSlots?.length) {
        setShiftSlot(fetchedShiftSlots[0]);
      }
    }
  }, [shift]);
  const colleagueList = useMemo(() => {
    return getColleagueList({
      hideAsColleagueOnShifts,
      workerFriends,
      workerFriendRequests,
      shiftSlotBookedByList: shiftSlot?.bookedBy || [],
      isShiftCompleted: true,
      userId,
    });
  }, [shiftSlot?.bookedBy, workerFriends, hideAsColleagueOnShifts, workerFriendRequests, userId]);

  useEffect(() => {
    if (shift) {
      fetchShiftSlot();
    }
  }, [shift, fetchShiftSlot]);

  const showTimeSheetSummaryModel = isNewTimeSheetEnabled && shift?.isInstantPay;

  const openTimesheetModel = async (isFromAlert?: boolean) => {
    // Do not switch to landscape for the revamped signout flow
    if (
      showTimeSheetSummaryModel &&
      platformIsCapacitor &&
      // Even if the revamped signout flow is enabled, we do not show it in California, which takes precedence
      ((!useRevampedWorkplaceSignoutIsEnabled && !useRevampedWorkplaceSignoutV2IsEnabled) ||
        isCaliforniaTimesheetEnabled)
    ) {
      await lockOrientation(LockScreenOrientationVariant.LANDSCAPE_SECONDARY);
      await StatusBar.hide();
    }
    if (isFromAlert) {
      setShowFileExplorer(true);
    }
    setShowUploadTimesheetModal(true);
  };

  const isNFCEnabled = shift?.nfcTag || false;

  const isSignatureSubmission = isNewTimeSheetEnabled && isDigitalSignatureEnabled;

  const closeTimesheetModel = async () => {
    if (showTimeSheetSummaryModel && platformIsCapacitor) {
      await unlockOrientation();
      await lockOrientation(LockScreenOrientationVariant.PORTRAIT_PRIMARY);
      await StatusBar.show();
    }
    setShowUploadTimesheetModal(false);
  };

  const history = useHistory();
  const worker = useDefinedWorker();

  const { cbh, state, msa, county, hcf } = useMissingDocuments(shift);

  const shiftDetailsAlerts = useDeprecatedAlertsForShiftDetails();

  const dispatch = useDispatch();

  const convertToBackUp = useCallback(async () => {
    await changeNFCTag(shiftId as string);
    const conversionReason = `Shift converted to location check - shift ${shiftId} got converted at ${new Date().toISOString()}.\nReason: NFC not supported by device.`;
    dispatch(actionRecordShiftTimeFailure(conversionReason, worker, shiftId as string));

    /* FIXME: https://linear.app/clipboardhealth/issue/DEV-648/fix-useeffect-exhaustive-deps-rule-in-myshiftdetailspage-component
     * Adding `agent` to the deps array causes an infinite re-render loop and
     * subsequent memory leak since it is an object that fails the comparison
     * by reference. We should refactor this to depend on `agent.userId` instead.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shiftId, dispatch]);

  /**
   * FIXME(TEV-228): replace with useQuery
   */
  const loadShiftDetails = useCallback(
    async (shiftId: string): Promise<Shift> => {
      setIsShiftDetailsLoading(true);
      let res = await fetchShiftDetails({ shiftId });

      if (res?.hasSentHomeRequest === true) {
        history.push(`/home/myShifts`);
      }

      if (res?.nfcTag) {
        const deviceNFCCapability = await deviceNFCCapabilityForShift();
        if (deviceNFCCapability === DeviceNFCCapabilityForShift.NO_NFC) {
          await convertToBackUp();
          res = await fetchShiftDetails({ shiftId });
        }
      }
      /*
        FIXME
         this API call should not be here,
         we should instead add the data required here to fetchShiftDetails,
         and remove this call
       */
      const facility = res.facility as Facility;
      facility.verification = res.facility?.verificationPreference;
      if (res?.facility?.userId) {
        const { data } = await fetchFacilityDetails(res.facility.userId);
        res.facility.verificationPreference = data;
      }
      setShift(res);
      setIsShiftDetailsLoading(false);
      return res; // if someone needs it before state updates
    },
    [convertToBackUp, history]
  );

  useEffect(() => {
    (async function () {
      try {
        // FIXME, convert to useGetQuery wrapper based on react-query and move to appV2
        const shift = await loadShiftDetails(shiftId);
        // refresh store shift with latest data from the api
        dispatch({
          type: ActionType.UPDATE_SHIFT,
          data: {
            shiftId: shift._id,
            updatedShift: shift,
          },
        });
      } catch (error) {
        setShiftLoadError(error);
        setIsShiftDetailsLoading(false);
      }
    })();
  }, [loadShiftDetails, shiftId, dispatch]);

  const isShiftAvailable =
    isDefined(shift) &&
    isDefined(shiftStateData) &&
    !shift.deleted &&
    shift.agentId === worker.userId;

  // here we listen to the app regaining focus because if the user
  // leaves the app open, and then a shift is canceled, we want
  // to display that to the user immediately
  useEffect(() => {
    const focusListener = CapacitorApp.addListener("appStateChange", async ({ isActive }) => {
      const isNFCOpen = JSON.parse(localStorage.getItem("isNFCOpen") as string);
      const isLocationAlertOpen = JSON.parse(localStorage.getItem("isLocationAlertOpen") as string);
      const networkStatus = await Network.getStatus();
      if (isActive && !isNFCOpen && networkStatus.connected && !isLocationAlertOpen) {
        await loadShiftDetails(shiftId);
      }
      if (isActive && isNFCOpen) {
        localStorage.setItem("isNFCOpen", "false");
      }
    });
    return () => {
      focusListener.then((listenerHandle) => listenerHandle.remove());
    };
  }, [loadShiftDetails, shiftId]);

  const shifts = useAppSelector((state) => state?.ongoingShiftStore?.shiftList ?? []);

  /*
  FIXME
    This update mechanism is primarily used by offline mode,
    so it can update this page even when shift clock and lunch
    times are updated locally without persisting to the server.
    We should revise this logic once we revise our offline mode
    because this was and can be a future source of bugs which happen by
    overwriting fields from the shift object. This is using a shallow copy mechanism
    which will overwrite deeply nested objects and remove information required
    for normal page functioning.
    It is also used for updating shift from some child components which
    mutate shift data, like converting shift to non instant pay.
 */
  useEffect(() => {
    const updatedShift = shifts?.find((item) => item?._id === shiftId);
    if (!shift?._id || !updatedShift) {
      return;
    }

    setShift((priorShift) => ({
      ...priorShift,
      ...omit(updatedShift, ["facility", "cancellationWithoutPenaltyThresholdTime"]),
    }));
  }, [shifts, shiftId, shift?._id]);

  useEffect(() => {
    if (!isShiftDetailsLoading && isShiftAvailable) {
      if (shift.timecardNotAvailable?.reason) {
        setCanShowUploadButton(false);
      }

      if (
        !shift.facility?.verificationPreference?.usesTimesheets &&
        !shift.facility?.requireTimecardPhoto
      ) {
        setIsTimeSheetRequired(false);
      }
    }
  }, [shift, userId, isShiftDetailsLoading, isShiftAvailable]);

  const formattedDate = shift && moment(shift?.start).format("ddd, MMMM DD");

  const uploadTimeSheet = async (isFromAlert?: boolean) => {
    const status = await Network.getStatus();
    if (!status.connected) {
      shiftDetailsAlerts.alertNoNetworkConnection({ isEdit: true });
      return;
    }
    openTimesheetModel(isFromAlert);
  };

  const cancelNoTimeSheet = () => setOpenNoTimeSheetOptions(false);

  const handleCloseColleagueList = () => {
    setIsRatingSuccess(false);
    goToOpenShifts();
  };

  const goToOpenShifts = () => {
    logEvent(USER_EVENTS.TAPPED_GO_TO_OPEN_SHIFTS);
    history.replace("/home/openShifts");
  };

  const hasMissingDocs = !!(cbh || state || msa || county || hcf);
  const timeSheetExists =
    (shift?.timecard?.files?.length as number) > 0 || !!shift?.timecardNotAvailable?.reason;

  const { alert } = shiftDetailsAlerts;

  const onSuccessfulUpload = async (params: { isWorkedBreakRequestCreated?: boolean } = {}) => {
    closeTimesheetModel();
    setCanShowUploadButton(false);
    // we can't just wait for React's setState updates, so we get it directly
    const updatedShift = await loadShiftDetails(shiftId);
    const isInstantPayShift =
      updatedShift.instantPayDetails?.is100InstantPayEnabled && updatedShift.isInstantPay;

    if (isInstantPayShift) {
      workShiftFeedbackModalState.openModal();
    } else {
      setOpenRating(true);
    }
  };

  const { appInfo } = useAppInfo();
  const title = getShiftTitleString({
    shift,
    shiftStateData,
    shiftStart: shift?.start,
  });
  const solveUnpaidBreaksRolloutStageWithTargetingConfig = shiftStateData?.metadata
    .solveUnpaidBreaksRolloutStageWithTargetingConfig ?? {
    stage: SolveUnpaidBreaksRolloutStage.OFF,
    minimalStoreVersion: SOLVE_UNPAID_BREAKS_FF_MATCH_ALL_VERSIONS,
    minimalOtaVersion: SOLVE_UNPAID_BREAKS_FF_MATCH_ALL_VERSIONS,
  };
  const isSolveUnpaidBreaksEnabled =
    isSignatureSubmission &&
    isSolveUnpaidBreaksFeatureEnabled({
      solveUnpaidBreaksRolloutStageWithTargetingConfig,
      appInfo,
    });

  const shiftFooterProps: ShiftActionFooterProps = {
    shift,
    shiftStateData,
    hasMissingDocs,
    isTimeSheetRequired,
    timeSheetExists,
    canShowUploadButton,
    loadShiftDetails,
    uploadTimeSheet,
    setOpenNoTimeSheetOptions,
    isNewTimeSheetEnabled,
    shiftDetailsAlerts,
    isSignatureSubmission,
    isNFCEnabled,
    isShiftLoading: areShiftRequestsLoading,
    isDigitalSignatureEnabled,
    isCaliforniaTimesheetEnabled,
    isUploadTimesheetNonIpEnabled,
    isSolveUnpaidBreaksEnabled,
    onSuccessfulTimeSheetUpload: onSuccessfulUpload,
  };

  const { attendanceScoreInfo: showAttendanceScoreInfo } = useCbhFlag(
    CbhFeatureFlag.ATTENDANCE_SCORE_INFO,
    {
      defaultValue: defaultAttendanceScoreFeatureFlag,
    }
  );
  const { data: attendanceScoreProfile, isSuccess: attendanceScoreProfileSuccess } =
    useGetAttendanceScoreProfile(worker?.userId);
  const reliabilityRankingExperiment = useReliabilityRankingExperiment();

  // FIXME - extract this logic out into a separate component in a new file as it is too complex to be placed inline.
  let ratingContent;

  if (isRatingSuccess) {
    if (!hideAsColleagueOnShifts && colleagueList?.length > 0) {
      ratingContent = (
        <IonModal
          cssClass="colleague-list-modal"
          isOpen={isRatingSuccess}
          onDidDismiss={() => handleCloseColleagueList()}
        >
          <ColleagueList
            shiftId={shift?._id}
            context={ShiftContext.MY_SHIFTS}
            isShiftCompleted={true}
            viewAddFriendModalContext="COLLEAGUES_LIST_POST_SHIFT_END"
          />
        </IonModal>
      );
    } else {
      ratingContent = (
        <RatingSuccess
          isOpen={isRatingSuccess}
          onClose={() => {
            setIsRatingSuccess(false);
            goToOpenShifts();
          }}
        />
      );
    }
  }

  const getInstantPayTotalAmount = () => {
    const instantPayTotalAmount = shift?.instantPayDetails?.totalAmount;
    if (isDefined(instantPayTotalAmount)) {
      return instantPayTotalAmount;
    }
    return (shift?.finalPay ?? 0) * (shift?.time ?? 0);
  };

  if (!areShiftRequestsLoading && !isShiftAvailable) {
    return <MyShiftUnavailablePage shift={shift} workerId={worker.userId} error={shiftLoadError} />;
  }

  const isShiftTimelineVisible = shift?.isInstantPay;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/home/myShifts" />
          </IonButtons>
          <IonTitle>{title || formattedDate}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <ShiftLoader loading={areShiftRequestsLoading} count={1} />
        <IonCard className="shift-details-card no-text-transform new-flow shifts ongoing">
          {!areShiftRequestsLoading && isShiftAvailable && (
            <>
              <ShiftTitle
                shift={shift}
                shiftStateData={shiftStateData}
                hasMissingDocs={hasMissingDocs}
                showMissingDocs={!shift?.agent}
                hidePayCanceledShift={shift?.deleted}
                showPaymentAs="hourly"
              />
              {isDefined(shift?.start) &&
                isDefined(shift?.end) &&
                isDefined(shift?.facility) &&
                isDefined(shift.facility.userId) && (
                  <StreakAlertWrapper
                    onlyCheckActiveStreak
                    shiftStartTime={parseISO(shift.start)}
                    shiftEndTime={parseISO(shift.end)}
                    workerId={worker.userId}
                    workplaceId={shift.facility.userId}
                    shiftId={shift._id}
                  />
                )}
              <FacilityDetails
                shift={shift}
                showCheckInInstructions={false}
                showMinimalDetails={Boolean(shift?.clockInOut)}
                onClickOnFacilityDetails={() => {
                  if (shift?.isInstantBook) {
                    logEvent(USER_EVENTS.VIEWED_FACILITY_PROFILE, {
                      bookingType: "instant book",
                    });
                  } else {
                    logEvent(USER_EVENTS.VIEWED_FACILITY_PROFILE, {
                      bookingType: "standard",
                    });
                  }
                }}
              />
              {shiftSlot && (
                <WorkWithFriends
                  shiftSlot={shiftSlot}
                  shift={shift}
                  context={ShiftContext.MY_SHIFTS}
                />
              )}
              <>
                {showAttendanceScoreInfo &&
                  attendanceScoreProfileSuccess &&
                  !reliabilityRankingExperiment.enabled && (
                    <>
                      <div className="hr" />
                      <ShiftAttendanceScoreAccordion
                        shift={shift}
                        attendanceScoreProfile={attendanceScoreProfile}
                      />
                    </>
                  )}
                <ShiftInstructions
                  hasMissingDocs={hasMissingDocs}
                  shift={shift!}
                  shiftStateData={shiftStateData}
                  isTimeSheetRequired={isTimeSheetRequired}
                  timeSheetExists={timeSheetExists}
                  isNewTimeSheetEnabled={isNewTimeSheetEnabled}
                  isSignatureSubmission={isSignatureSubmission}
                  isSolveUnpaidBreaksEnabled={isSolveUnpaidBreaksEnabled}
                />
                <MissingDocsDetails agent={shift?.agent} hasMissingDocs={hasMissingDocs} />
                {shift?.isChangedToNonInstantPay && (
                  <NonIPConversionText isAutoClockedOut={Boolean(shift.autoClockedOut)} />
                )}
              </>
            </>
          )}
        </IonCard>
        <NoTimeSheetOptions
          visible={openNoTimeSheetOptions}
          setVisible={setOpenNoTimeSheetOptions}
          cancel={cancelNoTimeSheet}
          shift={shift as Shift}
          setCanShowUploadButton={setCanShowUploadButton}
        />
      </IonContent>
      {!areShiftRequestsLoading && isShiftAvailable && isShiftTimelineVisible && (
        <ShiftTimeline shift={shift} isSolveUnpaidBreaksEnabled={isSolveUnpaidBreaksEnabled} />
      )}
      {ratingContent}
      {openRating &&
        (shift?.facility?.userId && shift?.facility?.type && shift?.facility?.name ? (
          <ReviewForm
            showIsRatingSuccessDialog={() => setIsRatingSuccess(true)}
            workplaceDetails={{
              id: shift?.facility?.userId,
              name: shift?.facility?.name,
              type: shift?.facility?.type,
            }}
          />
        ) : null)}

      {workShiftFeedbackModalState.modalIsOpen &&
        isDefined(shift) &&
        isDefined(shift._id) &&
        isDefined(shift.agentId) &&
        (isQualityBonusEnabled ? (
          <ClockOutPaymentMessageDialog
            isOpen={workShiftFeedbackModalState.modalIsOpen}
            onClose={() => {
              workShiftFeedbackModalState.closeModal();
              setOpenRating(true);
            }}
          />
        ) : (
          <WorkedShiftFeedbackModal
            modalState={workShiftFeedbackModalState}
            shiftEarnings={getInstantPayTotalAmount()}
            shiftId={shift._id}
            showWorkplaceRatingDialog={() => setOpenRating(true)}
            agentId={shift.agentId}
          />
        ))}

      {isShiftAvailable && <AttendancePolicyShiftActionFooter {...shiftFooterProps} />}

      {isShiftAvailable &&
        showUploadTimesheetModal &&
        (showTimeSheetSummaryModel ? (
          <TimeSheetSummary
            shift={shift}
            shiftStateData={shiftStateData}
            isSignatureSubmission={isSignatureSubmission}
            showFileExplorer={showFileExplorer}
            onCloseOrCancel={closeTimesheetModel}
            onSuccessfulUpload={onSuccessfulUpload}
            isNewTimeSheetEnabled={isNewTimeSheetEnabled}
            isCaliforniaTimesheetEnabled={isCaliforniaTimesheetEnabled}
            isSolveUnpaidBreaksEnabled={isSolveUnpaidBreaksEnabled}
            shiftDetailsAlerts={shiftDetailsAlerts}
          />
        ) : (
          <UploadFileModal
            shift={shift}
            shiftStateData={shiftStateData}
            onCloseOrCancel={() => setShowUploadTimesheetModal(false)}
            onSuccessfulUpload={onSuccessfulUpload}
          />
        ))}
      <IonAlert
        header={alert?.header}
        message={alert?.message}
        isOpen={!!alert}
        // onDidDismiss will override alert to null if we try to change the alert inside a modal button handler
        onWillDismiss={shiftDetailsAlerts.dismissAlert}
        buttons={alert?.buttons}
        mode="ios"
        cssClass="my-shift-details-alert"
      />

      {environmentConfig.REACT_APP_ENVIRONMENT_NAME !== DeploymentEnvironmentName.PRODUCTION &&
        (localStorage.getItem("areDevToolsVisible") ?? "true") === "true" && (
          <div style={{ zIndex: 1000 }}>
            <RadarSdkTestPanel shift={shift} />
          </div>
        )}
    </IonPage>
  );
}
